var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('BtnCircle',{attrs:{"tLabel":"Atrás","tPosition":"bottom","icon":"mdi-arrow-left","color":"grey darken-3","size":"x-small","to":{ name: _vm.page_route }}}),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(_vm.$route.meta.title)}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(_vm.$store.getters.getLogin.permissions.provider_groups.create)?_c('BtnCircle',{attrs:{"tLabel":"Editar","tPosition":"bottom","icon":"mdi-pencil","color":"info","size":"x-small","to":{ name: (_vm.page_route + ".update"), params: { id: _vm.id } }}}):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"text-caption",domProps:{"textContent":_vm._s('GENERAL')}})]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('ViewData',{attrs:{"label":"Nombre","value":_vm.data.provider_group}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"text-caption",domProps:{"textContent":_vm._s(
                ("PROVEEDORES " + (_vm.loading ? '' : ("(" + (_vm.data.provider_in_groups.length) + ")")))
              )}})]),_c('v-divider'),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar...","single-line":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"label":"Tipo*","items":_vm.provider_types,"item-value":"id","item-text":function (i) { return i.provider_type; },"dense":""},model:{value:(_vm.provider_type_id),callback:function ($$v) {_vm.provider_type_id=$$v},expression:"provider_type_id"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"label":"Estado*","items":_vm.entities,"item-value":"id","item-text":function (i) { return i.entity; },"loading":_vm.entities_loading,"dense":""},on:{"change":function($event){return _vm.getMunicipalities()}},model:{value:(_vm.entity_id),callback:function ($$v) {_vm.entity_id=$$v},expression:"entity_id"}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"label":"Municipio*","items":_vm.municipalities,"item-value":"id","item-text":function (i) { return i.municipality; },"loading":_vm.municipalities_loading,"dense":""},model:{value:(_vm.municipality_id),callback:function ($$v) {_vm.municipality_id=$$v},expression:"municipality_id"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('small',[_vm._v("*APT = Alta Apertura Terminada")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterProviders,"item-key":"id","search":_vm.search,"loading":_vm.loading,"dense":""},scopedSlots:_vm._u([{key:"item.memberGroup",fn:function(ref){
              var item = ref.item;
return [(item.member)?_c('label',[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" mdi-check ")])],1):_vm._e()]}},{key:"item.discharge",fn:function(ref){
              var item = ref.item;
return [(item.member && item.opening_discharge)?_c('label',[_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v(" mdi-check ")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h2',{staticClass:"text-caption",domProps:{"textContent":_vm._s('REGISTRO')}})]),_c('v-divider'),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"Creación","value":!_vm.loading && _vm.data.created_by.email,"subvalue":_vm.data.created_at}})],1),_c('v-col',{attrs:{"cols":"12","xs":"12","md":"6"}},[_c('ViewData',{attrs:{"label":"U. actualización","value":!_vm.loading && _vm.data.updated_by.email,"subvalue":_vm.data.updated_at}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.$store.getters.getLogin.permissions.provider_groups.delete)?_c('BtnCircle',{attrs:{"tLabel":"Desactivar","tPosition":"top","icon":"mdi-delete","color":"error","size":"x-small","click":_vm.HandleDesactivate,"loading":_vm.loading}}):_vm._e()],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }